import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/downloadApp/apple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/downloadApp/google.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/downloadApp/mobile.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/postAJob/1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/talent/talent1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/talent/talent2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/home/talent/talent3.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Consent/Cookie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Consent/page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Hero/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/PopularArticles/components/ArticlesCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/PopularServices/components/ServicesCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/src/components/ui/separator.tsx");
