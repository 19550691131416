"use client";

import type { Session } from "next-auth";
import Carousel, { type ResponsiveType } from "react-multi-carousel";

import type { BlogArticle } from "@/types";

import { CustomButtonGroup } from "@/components/carousels";
import ArticleCard from "@/components/cards/ArticleCard";

import "react-multi-carousel/lib/styles.css";

const responsive: ResponsiveType = {
	desktop: {
		breakpoint: { min: 1536, max: 3000 },
		items: 4,
	},
	laptopLarge: {
		breakpoint: { min: 1280, max: 1536 },
		items: 3,
	},
	laptopMedium: {
		breakpoint: { min: 1200, max: 1280 },
		items: 3,
		partialVisibilityGutter: 0,
	},
	laptop: {
		breakpoint: { min: 1024, max: 1199 },
		items: 2,
		partialVisibilityGutter: 75,
	},
	tabletLarge: {
		breakpoint: { min: 930, max: 1023 },
		items: 2,
		partialVisibilityGutter: 50,
	},
	tabletMedium: {
		breakpoint: { min: 880, max: 929 },
		items: 2,
		partialVisibilityGutter: 25,
	},
	tablet: {
		breakpoint: { min: 800, max: 879 },
		items: 2,
		partialVisibilityGutter: 0,
	},
	tabletSmall: {
		breakpoint: { min: 768, max: 799 },
		items: 1,
		partialVisibilityGutter: 300,
	},
	mobileLarge: {
		breakpoint: { min: 690, max: 767 },
		items: 1,
		partialVisibilityGutter: 250,
	},
	mobileMedium: {
		breakpoint: { min: 640, max: 689 },
		items: 1,
		partialVisibilityGutter: 200,
	},
	mobile: {
		breakpoint: { min: 0, max: 639 },
		items: 1,
	},
};

type Props = {
	session: Session | null;
	articles: BlogArticle[];
};

export default function ArticlesCarousel({ session, articles }: Props) {
	return (
		<div className="relative grid grid-rows-[26rem_2rem]">
			<Carousel
				responsive={responsive}
				ssr
				partialVisible
				arrows={false}
				showDots={false}
				customButtonGroup={<CustomButtonGroup itemLength={articles.length} />}
				renderButtonGroupOutside
				infinite
				keyBoardControl
				autoPlay
				autoPlaySpeed={5000}
				transitionDuration={500}
				customTransition="transform 500ms ease-in-out"
				className="-ml-2 h-[26rem] w-full !items-start pl-2"
				sliderClass="h-96 xl:space-x-4 2xl:space-x-6"
			>
				{articles.map((service, k) => (
					<ArticleCard key={k} session={session} data={service} intent="carousel" />
				))}
			</Carousel>
		</div>
	);
}
