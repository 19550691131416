"use client";

import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import type { Session } from "next-auth";
import type { MouseEventHandler } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { type BlogArticle } from "@/types";
import { isUserPremium } from "@/lib/utils";
import { useGetBlogLikesQuery } from "@/features/blog/blogApiInjection";

import { useToast } from "@/components/ui/use-toast";
import LikeCircleIcon from "@/components/icons/LikeCircle";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

const cardVariants = cva("flex flex-col rounded-lg !shadow-custom", {
	variants: {
		intent: {
			grid: "h-[22rem]",
			carousel: "h-96 w-[calc(100%-1rem)] sm:w-[22rem]",
		},
	},
	defaultVariants: { intent: "grid" },
});

const previewVariants = cva("w-full rounded-t-lg p-0", {
	variants: {
		intent: {
			grid: "h-52",
			carousel: "h-56",
		},
	},
	defaultVariants: { intent: "grid" },
});

type Props = VariantProps<typeof cardVariants> & {
	data: BlogArticle;
	session: Session | null;
	expansion?: {
		/**
		 * When set to true, all restrictions on user plans are removed from the navigation listener.
		 */
		noAuth: boolean;
		/**
		 * Configures the base path used when the card is clicked.
		 */
		basePathname: string;
	};
};

export default function ArticleCard({
	session,
	data,
	intent = "grid",
	expansion = { noAuth: false, basePathname: "/blogs" },
}: Props) {
	const { toast } = useToast();
	const { data: likes, isLoading: isLikesLoading } = useGetBlogLikesQuery({
		id: data._id,
		limit: 1,
	});

	const isBlogPremiumButNotUser = data.isPremium && !isUserPremium(session?.user.plan.duration);
	const href =
		expansion.noAuth || !isBlogPremiumButNotUser
			? `${expansion.basePathname}/${data.id}`
			: "javascript:;";

	const onClick: MouseEventHandler<HTMLAnchorElement> = () => {
		if (expansion.noAuth || !isBlogPremiumButNotUser) return;

		toast({
			variant: "destructive",
			title: "Free plan can't access premium content",
			description: "Upgrade to access content",
		});
	};

	return (
		<Link
			onClick={onClick}
			aria-disabled={!expansion.noAuth || isBlogPremiumButNotUser}
			href={href}
		>
			<Card className={cardVariants({ intent })}>
				<CardHeader aria-hidden className={previewVariants({ intent })}>
					<Image
						src={data.media[0].url}
						alt=""
						width={512}
						height={512}
						quality={80}
						className="size-full rounded-t-lg object-cover"
					/>
				</CardHeader>

				<div className="inline-flex h-10 items-center justify-between px-4">
					<span
						className={clsx("text-muted-foreground", {
							"text-xs": intent === "grid",
							"text-sm": intent === "carousel",
						})}
					>
						{data.blogCategories
							.slice(0, 2)
							.map((x) => x.name)
							.join(" | ")}
					</span>
					<div
						className={clsx(
							"flex h-6 w-20 items-center justify-center rounded-full font-medium",
							data.isPremium ? "bg-black text-tertiary" : "bg-tertiary text-white",
							{ "text-xxsm": intent === "grid", "text-xs": intent === "carousel" },
						)}
					>
						{data.isPremium ? "Premium" : "Free"}
					</div>
				</div>

				<CardTitle
					className={clsx("inline-flex flex-1 px-4 font-medium leading-snug", {
						"pt-1 text-sm2": intent === "grid",
						"pt-2 text-sm3": intent === "carousel",
					})}
				>
					{data.title}
				</CardTitle>

				<CardContent
					className={clsx(
						"inline-flex h-6 items-center justify-between px-4 text-grey-foreground",
						{ "text-xs": intent === "grid", "text-sm": intent === "carousel" },
					)}
				>
					<div className="flex items-center gap-2">
						<LikeCircleIcon className="size-5" />
						{isLikesLoading ? (
							<div className="h-4 w-12 animate-pulse rounded-md bg-muted" />
						) : (
							<span>{likes?.totalDocs}</span>
						)}
					</div>

					<span>{data.readingTime}</span>
				</CardContent>
			</Card>
		</Link>
	);
}
