import { Api } from "@/app/api";
import { AffiliateUser, UpdateUser, User, UserResponseDto } from "@/types";

export const userInjection = Api.injectEndpoints({
	endpoints: (builder) => ({
		getUsers: builder.query<UserResponseDto, void>({
			query: () => ({
				url: "/v1/users?pagination=false",
			}),
		}),

		getAggregatedUsers: builder.query<UserResponseDto, void>({
			query: () => ({
				url: "/v1/users?pagination=false&aggregated=true",
			}),
		}),

		getUser: builder.query<User, string>({
			query: (userId) => ({
				url: `/v1/users/${userId}`,
				headers: {
					"Content-Type": "application/json",
				},
			}),
			providesTags: ["user"],
		}),

		getAffiliatedUsers: builder.query<UserResponseDto, string>({
			query: (userId) => ({
				url: `/v1/users`,
				params: {
					affiliatedBy: userId,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		getAggregatedUsersByUserId: builder.query<AffiliateUser[], string>({
			query: (userId) => ({
				url: `/v1/users/${userId}/affiliate-view`,
			}),
		}),

		updateUser: builder.mutation<User, { userId: string; patchUser: UpdateUser }>({
			query: ({ userId, patchUser }) => ({
				url: `/v1/users/${userId}`,
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(patchUser),
				method: "PATCH",
			}),
			invalidatesTags: ["user"],
		}),

		searchUsers: builder.query<
			UserResponseDto,
			{ searchQuery?: string; sortBy?: string; page: number; limit: number }
		>({
			query: ({ searchQuery, sortBy, page, limit }) => ({
				url: `/v1/users`,
				params: {
					page: page,
					limit: limit,
					search: searchQuery,
					sortBy: sortBy,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetUserQuery,
	useUpdateUserMutation,
	useSearchUsersQuery,
	useGetAggregatedUsersQuery,
	useGetAggregatedUsersByUserIdQuery,
} = userInjection;
